.blog-list-container {
  flex: 1; /* Allow the blog list to grow and take available space */
  overflow-y: auto; /* Enable vertical scrolling */

  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff; /* Blue theme for the thumb */
    border-radius: 4px; /* Rounded corners */
    border: 2px solid #fff; /* White border for a minimal look */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }

  &::-webkit-scrollbar-track {
    background-color: #fff; /* Light gray track */
    border-radius: 4px; /* Rounded corners for the track */
  }

  /* Firefox scrollbar styling */
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
  scrollbar-color: #fff #fff; /* Thumb and track colors for Firefox */
}

.blog-list-container-wrapper {
  height: 120vh; /* Full viewport height */
  display: flex;
  flex-direction: column; /* Align content vertically */
  overflow: hidden; /* Prevent the parent container from scrolling */
}

/* Optional: Style for the "No data available" message */
.fab-no-data {
  color: #555;
  font-size: 1.2rem;
  margin-top: 20px;
}
