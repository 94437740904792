.fab-css-loader-container {
    z-index: 10000;

    .fab-css-loader-bg {
        background: #F1F1F1;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.12);
        border-radius: 6px;
        padding: 12px;
    }

    .fab-css-loader {
        border: 4px solid #549AFF;
        border-radius: 50%;
        border-top: 4px solid #F1F1F1;
        width: 35px;
        height: 35px;
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}