* {
  font-family: 'Barlow', sans-serif;
  font-size: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
  outline: none;
}

body {
  &.no-scroll {
    overflow: hidden;

    #app-main {
      width: 100%;
      overflow: hidden;
      position: fixed;

      @media only screen and (max-width: 600px) {
        position: absolute;
      }
    }

    #app-main-overlay {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 14;
    }

    @media only screen and (min-width: 601px) {
      overflow: hidden;
      position: fixed;
    }
  }
}

.fabcaller-app {
  .app-right-section {
    display: none;

    @media only screen and (min-width: 601px) {
      display: block;
      z-index: 10;
      margin: 0px;

      position: fixed;
      left: 540px;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: auto;

    }
  }

  .app-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;

    @media only screen and (min-width: 601px) {
      width: 70%;
    }
  }
}

.fab-cursor-pointer {
  cursor: pointer;
}

.fab-nav-link {
  cursor: pointer;
  text-decoration: none;

  &.primary-link {
    color: #549aff;
  }
}

.fab-no-scroll {
  overflow: hidden;
}

.fab-form-control {
  margin-top: 10px;
  margin-bottom: 10px;
}

.fab-form-label {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #121212;
  margin-bottom: 10px;
}

.fab-error-text {
  padding-top: 4px;
  font-size: 14px;
  color: red;
}

.fab-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 2rem;
    line-height: 26px;
    color: #121212;
  }

  a {
    color: #549AFF;
    font-size: 1.5rem;
  }

  span {
    font-size: 1.5rem;
    margin-top: 25px;
  }
}

.fab-privacy-policy {
  .fab-agreement-title {
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
  }

  .fab-agreement-desc {
    color: #667180;
    font-size: 14px;
    text-align: left;
  }
}

.fab-page-heading {
  width: 100%;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #121212;
}

.fab-page-heading-custom-pad {
  padding: 16px 16px 0px 16px;
}

.fab-section-title {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #549AFF;
  margin-bottom: 30px;
}

.fab-no-data {
  font-size: 2rem;
  color: #121212;
  margin-top: 100px;
}

.fab-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  border: 1px solid #CCE0FF;
  border-radius: 4px;
  padding: 3px;
  min-width: 30%;

  @media (max-width: 1440px) {
    min-width: 40%;
  }

  @media (max-width: 600px) {
    min-width: 60%;
  }

  .fab-btn-grp-active-btn {
    background: #549AFF;
    box-shadow: 2px 2px 4px rgba(17, 4, 53, 0.05);
    border-radius: 4px;

    &.fab-btn-grp-active-btn-text {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #FFFFFF;
    }
  }

  .fab-btn-grp-btn-text {
    padding: 8px 16px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #6E7A8A;
    width: 100%;
  }

}

.flex-container {
  display: flex;
  flex-direction: row;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.app-split-view {
  display: grid;
  grid-template-columns: 25% 5fr 2fr;
  min-height: calc(100vh - 120px);
  grid-template-rows: auto;
}

.left-section {
  overflow-y: auto;
  height: auto;
}

.middle-section,
.right-section {
  overflow-y: auto;
  height: 100%;
  max-height: 100%;
}

/* Media Query for Tablets (iPad and smaller screens) */
@media (max-width: 1024px) {
  .app-split-view {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
  }

  .right-section {
    grid-column: 1 / -1;
  }

  .left-section,
  .middle-section,
  .right-section {
    width: 100%;
    height: auto;
  }
}

/* Media Query for Smaller Screens (Phones) */
@media (max-width: 768px) {
  .app-split-view {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
  }

  .left-section,
  .middle-section,
  .right-section {
    width: 100%;
    height: auto;
  }
}

/* Optional: For even smaller screens (e.g., small phones), you can further adjust the layout */
@media (max-width: 480px) {
  .app-split-view {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .left-section,
  .middle-section,
  .right-section {
    width: 100%;
    height: auto;
  }
}

.blue-color {
  color: #549aff;
}