.blog-main-container {
    // border: 1px solid #cce0ff;

    .blog-description {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: normal;
    }

    .featured-img-wrapper {
        img {
            width: 100%;
            height: 125px;
            object-fit: cover;
        }
    }

    h6, h5 {
        color: #549AFF;
    }
}

.custom-margin-top {
    margin: 1px 16px 16px 16px;
}
